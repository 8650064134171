import React from "react";
import { makeStyles } from "@material-ui/core";
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
import "./AboutMePage.css";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(5),
    fontFamily: "Montserrat, sans-serif",
    backgroundColor: "transparent", 
    height: "100vh",
  },
  imageWrapper: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent", 
    borderRadius: "8px",
    padding: theme.spacing(1),
    width: "100%",
  },
  image: {
    width: "350px",
    height: "auto",
    marginRight: theme.spacing(2),
    borderRadius: "6px",
  },
  paragraph: {
    color: "#0A0B27",
    fontSize: "18px",
    lineHeight: "1.6",
    textAlign: "center",
    fontWeight: "100",
    backgroundColor: "transparent", 
  },
  timeline: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
  timelineItem: {
    display: "flex",
    color: "#0A0B27",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    fontWeight: "100",
    padding: theme.spacing(2),
    backgroundColor: "transparent",
    border: "1px solid black",
    borderRadius: "25px",
    width: "225px",
    margin: theme.spacing(2),
    position: "relative",
    transition: "background-color 0.3s ease",
    "&:hover": {
      transform: "scale(1.05)",
      backgroundColor: "#1855EE",
      scale: (1.05)
    },
    opacity: 0,
    transform: "translateX(-100%)",
    animation: "slideInFromLeft 1.5s ease forwards",
    
  },
    
  timelineArrow: {
    position: "absolute",
    top: "50%",
    width: "20px",
    height: "20px",
    transform: "translateY(-50%)",
    zIndex: -1,
  },
  timelineArrowLeft: {
    left: "-25px",
  },

}));

const AboutMePage = () => {
  const classes = useStyles();

  return (
    <div className={classes.pageWrapper}>
      <div className={classes.container}>
        <div className={classes.imageWrapper}>
          <img src="/DSC06901.jpg" alt="Profile Image" className={classes.image} />
        </div>
        <div>
          <p className={classes.paragraph}>
            Hi I'm Shawn Brown, and I'm a 2023 graduate of Mount Aloysius College with a Bachelor of Science degree in Information Technology, specializing in Cybersecurity/Digital Forensics. From a young age, I was captivated by the world of technology and computers. At the age of 14, I stumbled upon Python and discovered the thrill of developing text-based games. This sparked my passion for programming and set me on a path towards a career in IT.
          </p>
          <p className={classes.paragraph}>
            During my time in college, I immersed myself in cybersecurity, software development, and digital forensics. I constantly sought to expand my knowledge and skills in these areas, taking on challenging projects and participating in relevant courses. Alongside my academic pursuits, I also served as a Combat Engineer in the Army for six years, where I developed discipline, resilience, and a strong work ethic. This military experience further shaped my problem-solving abilities and instilled in me a sense of duty and commitment.
          </p>
          <p className={classes.paragraph}>
            After completing my service in the Army, I joined Asset Reality, a seized asset firm, as a Cryptocurrency Analyst / Tech Liaison. In this role, I gained valuable experience in analyzing cases regarding illicit cryptocurrency transactions. It provided me with insights into the fascinating world of cryptocurrencies. Now, as I move onto the next chapter of my professional journey, I am eager to apply my skills and knowledge to make a meaningful impact in the field of information technology. I thrive on staying updated with the latest advancements in cybersecurity, exploring new technologies, and taking on complex challenges.
            </p>
        </div>
        <div className={classes.timeline}>
          <div className={classes.timelineItem}>
            <h2 className={classes.timelineTitle}>2013</h2>
            <p className={classes.timelineDescription}>Began my Information Technology journey by developing python text-based games.<br></br>Began learning HTML, CSS and JavaScript, developing pages for a school website.</p>
          </div>
          <div className={classes.timelineItem}>
            <h2 className={classes.timelineTitle}>2014</h2>
            <p className={classes.timelineDescription}>Began learning development of 3D games, utilizing JavaScript and the Unity Engine. <br></br>Began attending a vocational school for half a day learning about Computer Networking.</p>
            <KeyboardArrowRightSharpIcon className={`${classes.timelineArrow} ${classes.timelineArrowLeft}`} />
          </div>
          <div className={classes.timelineItem}>
            <h2 className={classes.timelineTitle}>2016</h2>
            <p className={classes.timelineDescription}>Joined the United States Army as a Combat Engineer.<br></br></p>
            <KeyboardArrowRightSharpIcon className={`${classes.timelineArrow} ${classes.timelineArrowLeft}`} />
          </div>
          <div className={classes.timelineItem}>
            <h2 className={classes.timelineTitle}>2017</h2>
            <p className={classes.timelineDescription}>Scored an "Advanced" on the NOCTI PA state exam for computer networking.<br></br></p>
            <KeyboardArrowRightSharpIcon className={`${classes.timelineArrow} ${classes.timelineArrowLeft}`} />
          </div>
          <div className={classes.timelineItem}>
            <h2 className={classes.timelineTitle}>2018</h2>
            <p className={classes.timelineDescription}>Began pursuing a Bachelor of Science degree in Information Technology at Mount Aloysius College. <br></br></p>
            <KeyboardArrowRightSharpIcon className={`${classes.timelineArrow} ${classes.timelineArrowLeft}`} />
          </div>
          <div className={classes.timelineItem}>
            <h2 className={classes.timelineTitle}>2020</h2>
            <p className={classes.timelineDescription}>Deployed in support of Operation Freedom's Sentinel. <br></br></p>
            <KeyboardArrowRightSharpIcon className={`${classes.timelineArrow} ${classes.timelineArrowLeft}`} />
          </div>
          <div className={classes.timelineItem}>
            <h2 className={classes.timelineTitle}>2022</h2>
            <p className={classes.timelineDescription}>Began my professional career as a Cryptocurrency Analyst / Tech Liaison with Asset Reality.</p>
            <KeyboardArrowRightSharpIcon className={`${classes.timelineArrow} ${classes.timelineArrowLeft}`} />
          </div>
          <div className={classes.timelineItem}>
            <h2 className={classes.timelineTitle}>2023</h2>
            <p className={classes.timelineDescription}>Graduated with a Bachelor of Science degree in Information Technology from an NSA designated Cybersecurity/Digital Forensics institution of excellence.  <br></br></p>
            <KeyboardArrowRightSharpIcon className={`${classes.timelineArrow} ${classes.timelineArrowLeft}`} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMePage;