import React from "react";
import { Link } from "react-router-dom";
import { Card, CardContent, Typography, makeStyles } from "@material-ui/core";
import {FaNodeJs, FaReact, FaDigitalOcean, FaDiscord, FaUnity, FaGithub, FaPython} from "react-icons/fa"
import { RiSupabaseFill, RiJavascriptLine } from "react-icons/ri"
import { SiNginx, SiCsharp, SiMicrosoftazure, SiFlask } from "react-icons/si"
import "./projects.css";

const useStyles = makeStyles((theme) => ({
  container: {
    background: "clear",
    fontFamily: "Montserrat, sans-serif",
    margin: theme.spacing(4),
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center", 
  },
  h1: {
    textAlign: "left",
    color: "white",
    fontWeight: "100",
    fontFamily: "Montserrat, sans-serif",
    margin: theme.spacing(2),
    marginTop: theme.spacing(0),
  },
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
  card: {
    position: "relative",
    margin: theme.spacing(1), 
    fontFamily: "Montserrat, sans-serif",
    textDecoration: "none", 
    overflow: "hidden", 
    textOverflow: "ellipsis", 
    borderRadius: "25px",
    boxShadow: "0px 50px 50px #00000029",
    backgroundColor: "transparent",
    "&:hover": {
      transform: "scale(1.02)",
    },
    "&:hover $overlay": {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },

  overlay: {
    position: 'absolute',
    bottom: 100,
    backgroundColor: '#B2C8FF',
    borderRadius: "5px",
    color: '#1855EE',
    width: '96%',
    transition: 'opacity .5s ease, transform .5s ease',
    opacity: 0,
    transform: 'translateY(100%)', 
    padding: theme.spacing(1),
    textAlign: 'center',
  },

  card1: {
    width: "400px", 
    height: "250px",
    margin: theme.spacing(1), 
    fontFamily: "Montserrat, sans-serif",
    textDecoration: "none", 
    overflow: "hidden", 
    textOverflow: "ellipsis", 
    borderRadius: "25px",
    boxShadow: "0px 50px 50px #00000029",
    backgroundColor: "clear",
    "&:hover": {
      transform: "scale(1.02)",
    },

  },

  iconContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(1),
    "& svg": {
      fontSize: "17px", 
      margin: theme.spacing(.5), 
    },
  },
  cardContent: {
    textAlign: "center",
    minHeight: "250px",
    borderRadius: "3px",
    overflow: "hidden",
    flexGrow: 1,
    backgroundColor: "#B2C8FF",
    fontFamily: "Montserrat, sans-serif",
    transition: "background-color 0.3s ease",
    boxShadow: "0px 50px 50px #00000029",
    "&:hover": {
      backgroundColor: "#1855EE",
    },
    height: "100%", 
  },
  section_active: {
    backgroundColor: "clear",
    borderRadius: "8px",
    padding: theme.spacing(2),
    display: "flex", 
    flexWrap: "wrap", 
    justifyContent: "center", 
    alignItems: "center", 
  },
  description: {
    whiteSpace: "pre-line", 
  },
}));

const Projects = () => {
  const classes = useStyles();

  const projects = [
    {
      id: 1,
      title: "Crypto Sheets (2022 - Present)",
      description: "Cryptocurrency Forensics Visualization Tool",
      gifUrl: "crypto.gif", 
    },
    {
      id: 2,
      title: "Discord Bot (2021 - Present)",
      description: "A multi-functional Discord Bot",
      gifUrl: "bot.gif",
    },
    {
      id: 3,
      title: "2D browser game (2022 - Present)",
      description: "Unity Game",
      gifUrl: "game.gif",
    },

  ];

  const pastProjects = [
    {
      id: 4,
      title: "Project NPL (2014 - 2015)",
      description: "3D Parkour Game",
      gifUrl: "npl.gif",
    },
    {
      id: 5,
      title: "Star Citizen Ship MVC App (2022)",
      description:
        "Familiarization project with Azure",
        gifUrl: "mvc.gif",
    },
    {
      id: 7,
      title: "Original TXID to CSV tool (2022)",
      description:
        "Precursor to Crypto Sheets",
        gifUrl: "txid.gif",
    },
    {
      id: 8,
      title: "Original Discord Bot (2021)",
      description:
      "Precursor to the Node.js Discord Bot",
      gifUrl: "pybot.gif",
    }
  ];
  const publications = [
    {
      id: 6,
      title: "Tracing Illicit Cryptocurrency Funds with Maltego blog post. (Dec 2022)",
      description:
        "The fundamentals of utilizing Maltego to trace illicit cryptocurrency transactions.",
    },
    {
      id: 7,
      title: "Presentation: Identification of the tactics, trends and abilities of cybercriminals in the digital-asset realm. (Apr 2023)",
      description: "A presentation discussing digital-asset based cybercrime.",
    },
  ];
  

  return (
    <div className={classes.container}>
      <div className={classes.heading}>
      <h1 className={classes.h1}>Active Projects</h1>
      </div>
      <div className={`${classes.section_active} section_active`}>
        {projects.map((project) => (
          <Link
            key={project.id}
            to={`/projects/${project.id}`}
            className={`${classes.card} ${classes.cardHover}`}
          >
              <img src={project.gifUrl} alt={project.title} style={{ width: '100%', height: 'auto' }} />   

              <div className={classes.overlay}>
              <Typography variant="p" component="p">
                {project.title}
              </Typography>
              <Typography variant="body2">
                {project.description}
              </Typography>

              
            </div>

          </Link>
        ))}
      </div>
      <div className={classes.heading}>
      <h1 className={classes.h1}>Past Projects</h1>
      </div>
      <div className={`${classes.section_active} section_active`}>
        {pastProjects.map((project) => (
          <Link
            key={project.id}
            to={`/projects_past/${project.id}`}
            className={`${classes.card} ${classes.cardHover}`}
          >
              <img src={project.gifUrl} alt={project.title} style={{ width: '100%', height: 'auto' }} />
              <div className={classes.overlay}>
              <Typography variant="p" component="p">
                {project.title}
              </Typography>
              <Typography variant="body2">
                {project.description}
              </Typography>
            </div>


          </Link>
        ))}
      </div>
      <div className={classes.heading}>
        <h1 className={classes.h1}>Publications</h1>
        </div>
      <div className={`${classes.section_active} section_active`}>
        {publications.map((project) => (
          <Link
            key={project.id}
            to={`/projects/${project.id}`}
            className={`${classes.card1} ${classes.cardHover}`}
          >
            <Card variant="basic">
              <CardContent className={classes.cardContent}>
                <Typography variant="h6" component="h2" className={classes.typography}>
                  {project.title}
                </Typography>
                <Typography
                  color="textSecondary"
                  className={`${classes.typography} ${classes.description}`}
                >
                  {project.description}
                </Typography>
              </CardContent>
            </Card>
          </Link>
        ))}
      </div>
    </div>
    
  );
};

export default Projects;
