import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  navbar: {
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    position: "static",
  },

  name: {
    fontSize: "32px",
    fontWeight: 100,
    color: "#1111111",
    margin: theme.spacing(1),
    textAlign: "center",
    fontFamily: "Montserrat, sans-serif",
  },
  subtitle: {
    fontSize: "16px",
    fontWeight: 100,
    fontStyle: "italic", 
    color: "#1111111",
    margin: theme.spacing(1),
    textAlign: "center",
    fontFamily: "Montserrat, sans-serif",
  },
  navlinks: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(2),
    position: "relative",
  },
  link: {
    textDecoration: "none",
    margin: "0 12px",
    fontFamily: "Montserrat, sans-serif",
    color: "#a3a3a3"
  },
  button: {
    textTransform: "none",
    color: "#000", 
    fontSize: "15px",
    fontFamily: "Montserrat, sans-serif",
    backgroundColor: "#B2C8FF", 
    borderRadius: "20px", 
    padding: theme.spacing(1, 3), 
    border: "none", 
    boxShadow: "0px 10px 10px #00000029", 
    "&:hover": {
      backgroundColor: "#1855EE", 
      opacity: 0.8,
      boxShadow: "0px 3px 6px #00000029",
    },
  },
  appBar: {
    background: "transparent",
    boxShadow: "none",
  },
  logo: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
  },
}));

function Navbar() {
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar>
        <div className={classes.navbar}>
          <Typography variant="h4" className={classes.name}>
            Shawn Brown
          </Typography>
          <Typography variant="body2" className={classes.subtitle}>
            Cybersecurity Analyst, Cryptocurrency Investigator, Digital Forensics Examiner, Software Developer
          </Typography>
          <Typography variant="body3" className={classes.subtitle}>
            
            Mount Aloysius College '23 Bachelor of Science Information Technology
          </Typography>
          <Typography variant="body3" className={classes.subtitle}>
          United States Army Veteran 2016-2022
          </Typography>
          <div className={classes.navlinks}>
            <Link to="/" className={classes.link}>
              <Button className={classes.button}>Home</Button>
            </Link>
            <Link to="/about" className={classes.link}>
              <Button className={classes.button}>About</Button>
            </Link>
            <Link to="/projects" className={classes.link}>
              <Button className={classes.button}>Projects</Button>
            </Link>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;