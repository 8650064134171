import React from "react";
import { useParams } from "react-router-dom";
import { makeStyles, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
    textAlign: "center",
  },
  title: {
    fontSize: "32px",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    paddingBottom: theme.spacing(1),
  },
  description: {
    marginBottom: theme.spacing(2),
    textAlign: "left",
    marginLeft: theme.spacing(2),
    fontSize: "24px",
    alignItems: "center",
  },
  button: {
    margin: theme.spacing(2),
  },
  image: {
    width: "900px",
    height: "auto",
    display: "flex",
    flexDirection: "row",
    marginRight: theme.spacing(2),
    cursor: "pointer",
    alignSelf: "center",
    padding: "5px",
    flex: "33.33%"
  },
  imageContainer: {
    display: "flex",
    flexDirection: "row",
    alignSelf: "center",
    padding: "5px",
    flex: "33.33%"
  },
  clickableImage: {
    cursor: "pointer",
  },
  
}));

const ProjectDetailsPage = () => {
  const classes = useStyles();
  const { projectId } = useParams();

  // Example project data
  const projects = [
      {
        id: 1,
        title: "Cryptocurrency Analyst/Software Developer Liason @ Asset Reality",
        description: "Analyzed 100+ cases of suspected illicit cryptocurrency transactions.\n- Analyzed a variety of blockchains including but not limited to, Ethereum, Bitcoin, TRON, BSC and their respective tokens.\n- Analysis reports sent to victims/law enforcement internationally.\n- Identified threats poised at the cryptocurrency community, including (not limited to) investment scams, typosquatting, malware, SIM swapping\n- Liaisoned between the Software Development and Investigations unit for product implementations.",
      },
      {
        id: 2,
        title: "Combat Engineer @ United States Army Reserve",
        description: "Global War on Terrorism Expeditionary Medal, serving in support of Operation Freedom's Sentinel.\n- Global War on Terrorism Service Medal, awarded for suporting counter terrorism operations on active duty.\n- Army Reserve Component Achievement Medal x2, awarded for honorable and exemplary service. ",
        images: ["/mvc.jpg", "/mvc_2.jpg"],
      },
      {
        id: 4,
        title: "Mount Aloysius College (2018 - 2023)",
        description: "Awarded a Bachelor of Science in Information Technology. \n- Developed skill in relevant fields including (not limited to), cybersecurity, digital forensics, software development. \n- Took user input of an ETH transaction hash or list of ETH transaction hashes and retrieved/output relevant transaction information into a CSV. ",
        images: ["/txid.jpg", "/txid2.jpg"]
      }
    // Rest of the projects
    
  ];

  const project = projects.find((project) => project.id.toString() === projectId);

  if (!project) {
    return <div>Project not found</div>;
  }

  const descriptionItems = project.description.split("\n-").map((item) => item.trim());

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Notable Achievements</h1>
      <h2 className={classes.title}>{project.title}</h2>
        <ul className={classes.description}>
          {descriptionItems.map((item, index) => (
           <li key={index}>{item}</li>
          ))}   
        </ul>

      <Button
        variant="contained"
        color="secondary"
        className={classes.button}
        onClick={() => window.history.back()}
      >
        Back
      </Button>
    </div>
  );
};

export default ProjectDetailsPage;