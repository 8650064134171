import React from "react";
import { useParams } from "react-router-dom";
import { makeStyles, Button } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    padding: theme.spacing(2),
    textAlign: "center",
    fontFamily: "Montserrat, sans-serif",
    opacity: 0, 
    animation: "$fadeIn 0.5s ease-in-out forwards", 
  },
  title: {
    fontSize: "32px",
    fontWeight: "100",
    marginBottom: theme.spacing(2),
    borderBottom: `2px #0A0B27 solid`,
    paddingBottom: theme.spacing(1),
    color: "#0A0B27",
  },
  description: {
    marginBottom: theme.spacing(2),
    textAlign: "left",
    marginLeft: theme.spacing(2),
    fontSize: "24px",
    fontWeight: "100",
    alignItems: "center",
    color: "#0A0B27",
  },
  button: {
    textTransform: "none",
    color: "#000", 
    fontSize: "15px",
    fontFamily: "Montserrat, sans-serif",
    backgroundColor: "#B2C8FF", 
    borderRadius: "20px", 
    padding: theme.spacing(1, 3), 
    border: "none", 
    boxShadow: "0px 10px 10px #00000029", 
    marginBottom: theme.spacing(2), 
    "&:hover": {
      backgroundColor: "#1855EE", 
      opacity: 0.8,
      boxShadow: "0px 3px 6px #00000029",
    },
  },
  imageContainer: {
    display: "flex",
    flexWrap: "wrap", 
    justifyContent: "center", 
    margin: `-${theme.spacing(1)}px`, 
  },
  image: {
    width: "900px",
    height: "auto",
    display: "flex",
    flexDirection: "row",
    marginRight: theme.spacing(2),
    cursor: "pointer",
    alignSelf: "center",
    padding: "5px",
    flex: "33.33%",
  },
  
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
      transform: "translateX(-50px)", 
    },
    "100%": {
      opacity: 1,
      transform: "translateX(0)", 
    },
  },
}));

const ProjectDetailsPage = () => {
  const classes = useStyles();
  const { projectId } = useParams();

  
  const projects = [
    {
      id: 4,
      title: "Project NPL",
      description:
        "3D Parkour Game.\n- Developed into a playable demo in 2015.\n- Featured ziplining, wallrunning, time trials, AI races, and more.\n- Technologies Included: JavaScript, Unity Engine, Custom Assets\n- Playable demo was distributed through IndieDB in 2015",
      images: ["/screen.jpg", "/game2.png"], 
    },
    {
      id: 5,
      title: "Star Citizen Ship Database MVC Core App (C#)(2022)",
      description:
        "This was a familiarization project with the intention to expand my knowledge utilizing the MVC app structure and learning the Azure Cloud Infrastructure.\n- The application could list entries from database tables.\n- Search using given criteria, and returning modified list to user. \n- User accounts and roles, providing authentication.\n- Technologies Included: C#, ASP.NET Core MVC, Entity Framework Core, SQL Server, Azure Cloud Infrastructure\n- Hosted via Microsoft Azure.",
      images: ["/mvc.jpg", "/mvc_2.jpg"],
    },
    {
      id: 7,
      title: "Original TXID to CSV tool",
      description:
        "Prior to the development of crypto-sheets, a shell based Python tool was created with similar functionality. \n- Functionality was limited to Ethereum and Ethereum tokens. \n- Took user input of an ETH transaction hash or list of ETH transaction hashes and retrieved/output relevant transaction information into a CSV.\n-Technologies Included: Python, pyfiglet ",
      images: ["/txid.jpg", "/txid2.jpg"],
    },
    {
      id: 8,
      title: "Original Discord Bot",
      description:
        "Prior to the migration to Node.js, the Cred Bot was python-based.\n- Began development in April 2021. \n- Included the same features as the current Node.js iteration of the Cred Bot.\n-Technologies Included: Python, Discord.py, Flask, Multi-threading, SMTP, Twillio. ",
      images: ["/credbotpy.jpg", "/credbotpy2.jpg"],
    },
    
  ];

  const project = projects.find((project) => project.id.toString() === projectId);

  if (!project) {
    return <div>Project not found</div>;
  }

  const descriptionItems = project.description.split("\n-").map((item) => item.trim());
  const handleImageClick = (image) => {
    window.open(image, "_blank");
  };

  return (
    <div className={classes.container}>
      <h2 className={classes.title}>{project.title}</h2>
      <div className={classes.imageContainer}>
        {project.images.map((image, index) => (
          <img
            src={image}
            alt={`Project Image ${index + 1}`}
            className={classes.image}
            key={index}
            onClick={() => handleImageClick(image)}
          />
        ))}
      </div>
      <ul className={classes.description}>
        {descriptionItems.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <Button variant="contained" color="transparent" className={classes.button} onClick={() => window.history.back()}>
        Back
      </Button>
    </div>
  );
};

export default ProjectDetailsPage;