import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { makeStyles, Button } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    padding: theme.spacing(2),
    textAlign: "center",
    fontFamily: "Montserrat, sans-serif",
    opacity: 0,
    transform: "translateX(-100%)",
    animation: "$fadeIn 0.5s ease forwards",
  },
  title: {
    fontSize: "32px",
    fontWeight: "100",
    fontFamily: "Montserrat",
    marginBottom: theme.spacing(2),
    borderBottom: `2px #0A0B27 solid`,
    paddingBottom: theme.spacing(1),
    color: "#0A0B27",
  },
  description: {
    marginBottom: theme.spacing(2),
    textAlign: "left",
    fontWeight: "100",
    marginLeft: theme.spacing(2),
    fontSize: "24px",
    alignItems: "center",
    color: "#0A0B27",
  },
  button: {
    textTransform: "none",
    color: "#000", 
    fontSize: "15px",
    fontFamily: "Montserrat, sans-serif",
    backgroundColor: "#B2C8FF", 
    borderRadius: "20px", 
    padding: theme.spacing(1, 3), 
    border: "none", 
    boxShadow: "0px 10px 10px #00000029", 
    marginBottom: theme.spacing(2), 
    "&:hover": {
      backgroundColor: "#1855EE", 
      opacity: 0.8,
      boxShadow: "0px 3px 6px #00000029",
    },
  },
  imageContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    margin: `-${theme.spacing(1)}px`,
  },
  image: {
    width: "900px",
    height: "auto",
    display: "flex",
    flexDirection: "row",
    marginRight: theme.spacing(2),
    cursor: "pointer",
    alignSelf: "center",
    padding: "5px",
    flex: "33.33%",
  },
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
      transform: "translateX(-50px)", 
    },
    "100%": {
      opacity: 1,
      transform: "translateX(0)", 
    },
  },
}));


const ProjectDetailsPage = () => {
  const classes = useStyles();
  const { projectId } = useParams();
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    container.style.opacity = 1;
    container.style.transform = "translateX(0)";
  }, []);

  const projects = [
    {
      id: 1,
      title: "TXID to CSV webapp (crypto-sheets.net)",
      description:
        "A platform for Cryptocurrency Forensics Professionals to aggregate transaction information and easily produce transaction flow visualizations.\n- Originally derived from a Python-based tool with similar functionality, developed by the same team. \n- Recieves transaction hashes by the user, retrieves transaction information, then aggregates the transaction information into a CSV format.\n- Major updates available for preview upon request!\n-Technologies Include: Node.js, React.js, Express.js, Supabase, Nginx\n- Hosted on a cloud Linux Ubuntu server via Digital Ocean.",
      link: "https://crypto-sheets.net",
      images: ["/postPic1.png", "/postPic2.png"],
    },
    {
      id: 2,
      title: "Discord Bot (Cred Bot)",
      description:
        "A multi-functional Discord Bot.\n- Originally developed in Python but has since migrated to Node.JS\n- Features Include: Currency System ($CRED), Mute, Deafen, Lag, Chaos, Text Alerts, Temporary Email System, Wake Up Calls\n- Frontend dashboard coming soon.\n- Technologies Include: Node.js, React.js, Discord.js, MongoDB, Nginx\n- Hosted on a cloud Linux Ubuntu server via Digital Ocean." ,
      link: "https://www.youtube.com/playlist?list=PLwPfpbDZy9GFGLTBno_hFtq96YfSM2o4J",
      images: ["/mlag.jpg", "/gcr.jpg"],
    },
    {
      id: 3,
      title: "Unity Game ",
      description:
        "Inspired by mid-late 2000's browser games.\n- Updated UI elements, finished, coming soon.\n- Marathon Mode, finished, coming soon.\n-Technologies Include: C#, Unity Engine, Custom Assets\n- Hosted via Github.",
      link: "https://operance.github.io/PortfolioBuild/index.html",
      images: ["/game.jpg", "/game2.jpg"],
    },
    {
      id: 4,
      title: "Project NPL ",
      description:
        "3D Parkour Game.\n- Developed into a playable demo in 2015.\n- Featured ziplining, wallrunning, time trials.",
      link: "https://github.com/Operance/NPL/tree/master/Project%20NPL/Assets/Scripts",
      images: ["/screen.jpg", "path/to/image2.jpg"], 
    },
    {
      id: 5,
      title: "Star Citizen Ship Database MVC Core App ",
      description:
        "This is a familiarization project with the intention to expand my knowledge utilizing the MVC app structure and learning the Azure Cloud Infrastructure.\n- List entries from database tables.\n- Search using given criteria, and returning modified list to user. \n- User accounts and roles.  ",
      link: "blah",
      images: ["/mvc.jpg", "/mvc_2.jpg"],
    },
    {
      id: 6,
      title: "Tracing illicit cryptocurrency funds with Maltego.",
      description:
        "The Tatum Blockchain Explorer Transform Hub can be used to trace the movement of illicit cryptocurrency funds.\n- Identification of addresses that interact with sanctioned entities. \n- Swap Service attributions to addresses can be determined when used in conjunction with Blockchain Explorers such as Etherscan.io \n- Attributions between separate cryptocurrency addresses can be established via the Tatum Blockchain Explorer.\n- Visualizations can be produced via the Tatum Blockchain Explorer for reporting purposes.\n- Approved, awaiting publication. ",
      link: "https://docs.google.com/document/d/1XpMtgHj56MEhav5nLGL0xwDOcEMp2EXP7OvfzPFa9Go/edit?usp=sharing",
      images: ["/screen_2malt.png", "/screen_4malt.png"],
    },
        {
      id: 7,
      title: "Identification of the tactics, trends, and abilities of cybercriminals in the digital-asset realm.",
      description:
        "Historical Cases of Financial Crime.\n- Recent Cases of Digital-Asset Based Financial Crime. \n- Drawing Correlations Between Historical and Emerging Financial Crime. \n- Tactics and Abilities of Cybercriminals in the Digital-Asset Realm.\n- How Should Information Technology Professionals React? ",
      link: "https://youtu.be/2cH1dfItUHA",
      images: ["/presentation_screen1.jpg", "/presentation_screen2.jpg"],
    },
   
  ];

  const project = projects.find((project) => project.id.toString() === projectId);

  if (!project) {
    return <div>Project not found</div>;
  }

  const descriptionItems = project.description.split("\n-").map((item) => item.trim());
  const handleImageClick = (image) => {
    window.open(image, "_blank");
  };

  return (
    <div className={classes.container} ref={containerRef}>
      <h2 className={classes.title}>{project.title}</h2>
      <div className={classes.imageContainer}>
        {project.images.map((image, index) => (
          <img
            src={image}
            alt={`Project Image ${index + 1}`}
            className={classes.image}
            key={index}
            onClick={() => handleImageClick(image)}
          />
        ))}
      </div>
      <ul className={classes.description}>
        {descriptionItems.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <Button
        variant="contained"
        color="transparent"
        className={classes.button}
        href={project.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        Visit Project
      </Button>
      <Button
        variant="contained"
        color="transparent"
        className={classes.button}
        onClick={() => window.history.back()}
      >
        Back
      </Button>
    </div>
  );
};

export default ProjectDetailsPage;