import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Component/Navbar/navbar";
import Projects from "./Component/Projects/projects";
import ProjectDetailsPage from "./Component/ProductDetailsPage/ProductDetailsPage";
import ProjectDetailsPage_past from "./Component/ProductDetailsPage/ProjectDetailsPage_past";
import About from "./Component/About/about";
import AboutDetailsPage from "./Component/AboutDetailsPage/AboutDetailsPage";
import LinkedIn from "@material-ui/icons/LinkedIn";
import GitHub from "@material-ui/icons/GitHub.js";
import "./Homepage.css"; 
import ReactGA from "react-ga";

import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { lightBlue } from "@material-ui/core/colors";

function HomePage() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="container">
      <div className="background-box"></div>
      <div className="content">
        <p>Welcome to my portfolio website! Connect with me below!</p>
        <div className="social-icons">
          <Link to="https://www.linkedin.com/in/stbrown98/" target="_blank">
            <LinkedIn style={{ color: "B2C8FF" , fontSize: "50px" }} />
          </Link>
          <Link to="https://github.com/Operance" target="_blank">
            <GitHub style={{ color: "B2C8FF", fontSize: "50px" }} />
          </Link>
        </div>
      </div>
    </div>
  );
}

function App() {
  useEffect(() => {
    ReactGA.initialize("G-G0MWB0QJJ0"); 
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/about" element={<About />} />
        <Route path="/about/:projectId" element={<AboutDetailsPage />} />
        <Route path="/projects/:projectId" element={<ProjectDetailsPage />} />
        <Route
          path="/projects_past/:projectId"
          element={<ProjectDetailsPage_past />}
        />
      </Routes>
    </Router>
  );
}

export default App;